<template>
  <div class="wrapper">
    <div class="item"  @click="lookDetails('rencai')">
      <div class="img_con">
        <img src="../assets/image/solution/solution_rencai.png" alt="">
        <img class="fade_img" src="../assets/image/solution/solution_rencai2.png" alt="">
      </div>
      <label>智慧物联</label>
      <p>制定不同设备标准化规范、横向拉通硬件产品共性，提升一体化管理能力；加强软硬件产品应用场景的融合，实现产品价值提升，双向促进软件与硬件产品用户粘性提高。</p>
      <div class="btn_detail">了解详情</div>
    </div>
    <div class="item" @click="lookDetails('dichan')">
      <div class="img_con">
        <img src="../assets/image/solution/solution_dichan.png" alt="">
        <img class="fade_img" src="../assets/image/solution/solution_dichan2.png" alt="">
      </div>
      <label>智慧物业</label>
      <p>面向物业公司、地产公司、后勤集团公司提供总部级智慧物业解决方案，赋能合作企业快速实现由传统的规范管理能力，拓展多经服务水平，全面提升效率和客户服务质量，向智慧管理与降本增效能力的转型升级。</p>
      <div class="btn_detail">了解详情</div>
    </div>
    <div class="item" @click="lookDetails('shangye')">
      <div class="img_con">
        <img src="../assets/image/solution/solution_shangye.png" alt="">
        <img class="fade_img" src="../assets/image/solution/solution_shangye2.png" alt="">
      </div>
      <label>智慧区镇</label>
      <p>面向街道、乡镇的基层工作、创城创卫、共建共治的核心需求，结合AI、IoT、大数据等相关技术，提供社区综合治理、服务工单引擎、社区信息中台等应用，以满足日益精细化的基层治理要求。</p>
      <div class="btn_detail">了解详情</div>
    </div>
    <div class="item" @click="lookDetails('xitong')">
      <div class="img_con">
        <img src="../assets/image/solution/solution_xitong.png" alt="" width="72">
        <img class="fade_img" src="../assets/image/solution/solution_xitong2.png" alt="" width="72">
      </div>
      <label>智慧园区</label>
      <p>向园区运营方提供涵盖园区经营管理、物业服务管理、园区风险识别、设施设备管理及智能监控的整体解决方案，以IOC运营大屏，将园区全方位管理系统的数据进行采集与分析，赋能数字化园区管理。</p>
      <div class="btn_detail">了解详情</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionItem",
  methods:{
    lookDetails(name){
      this.$router.push({name:'solutionDetails',params:{name:name}})
    }
  }
}
</script>

<style scoped lang="less">
.wrapper{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0px 0px 29px 0px rgba(213,217,222,.35);
  .item{
    width: 25%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 45px;
    position: relative;
    cursor: pointer;
    transition: all .38s ease;
    .img_con{
      width: 70px;
      height: 70px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        position: absolute;
      }
      .fade_img{
        opacity: 0;
        transition: all .38s linear;
      }
    }
    label{
      font-size: 16px;
      font-weight: 600;
      color: #0B1D30;
      padding: 30px 0;
      cursor: pointer;
    }
    p{
      color: #42506c;
      font-size: 13px;
      text-align: left;
      height: 120px;
      margin: 0;
    }
    .btn_detail{
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #016FFF;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: all .38s linear;
    }
    &:hover{
      box-shadow: 0px 7px 22px 10px rgba(0, 0, 0, 0.08);
      label{
        color: #0D2A42;
      }
      .btn_detail{
        opacity: 1;
      }
      .img_con{
        .fade_img{
          opacity: 1;
        }
      }
    }
    & + .item{
      border-left: solid 1px #F4F5FA;
    }
  }
}
@media screen and (max-width: 768px){
  .wrapper{
    width: 100%;
    padding: 0 10px;
    .item{
      width: 50%;
      padding: 10px;
      border: 1px solid #EEEEEE;
    }
  }
}
</style>
